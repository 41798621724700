import { computed, reactive, readonly } from "@vue/composition-api";

const state = reactive({ info: {} });

if (window.localStorage.getItem("userData")) {
  state.info = JSON.parse(localStorage.getItem("userData"));
}

const setUserData = (val) => (state.info = val);

const userData = computed(() => state.info);
const userId = computed(() => state.info.id);
const userName = computed(() => state.info.username);
const userEmail = computed(() => state.info.email);
const userType = computed(() => state.info.type);

const profilePicture = computed(() => {
  if (state.info.profile_picture_url) return state.info.profile_picture_url;
  return require("@/assets/default-profile-pic.jpg");
});

const accessToken = () => localStorage.getItem("accessToken");

export function useUser() {
  return {
    state: readonly(state),

    setUserData,

    userId,
    userData,
    userType,
    userName,
    userEmail,
    profilePicture,
    accessToken,
  };
}
