<template>
  <v-app class="">
    <v-app-bar app>
      <v-app-bar-nav-icon
        @click="state.isDrawerOpen = true"
      ></v-app-bar-nav-icon>

      <router-link class="ml-2 mr-4 d-none d-md-block" to="/">
        <v-img
          max-height="100%"
          width="80px"
          :src="require('@/assets/logos/logo-nav.png')"
          alt=""
        ></v-img>
      </router-link>

      <div class="ml-auto mr-4">
        <v-avatar size="35px">
          <img :src="profilePicture" alt="" />
        </v-avatar>
        <small class="ml-2">{{ userData.username }}</small>
      </div>

      <div>
        <v-btn @click="logout" small>
          Logout
          <v-icon class="mr-2" small>{{ icons.mdiLogout }}</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <div class="d-flex flex-row">
      <v-navigation-drawer v-model="state.isDrawerOpen" absolute temporary>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              Admin Dashboard
            </v-list-item-title>
            <v-list-item-subtitle> HireHeroes </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item v-for="item in navMenuItems" :key="item.to" :to="item.to">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-navigation-drawer>

      <v-main>
        <slot></slot>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import { reactive } from "@vue/composition-api";
import { useUser } from "@/composables/user/user";

import {
  mdiLogout,
  mdiViewDashboard,
  mdiMessage,
  mdiClipboardAccount,
  mdiAccount,
} from "@mdi/js";

export default {
  name: "LayoutContent",
  setup() {
    const state = reactive({
      isDrawerOpen: false,
    });

    const { userData, profilePicture } = useUser();

    const navMenuItems = [
      {
        text: "Dashboard",
        to: "/dashboard",
        icon: mdiViewDashboard,
      },
      {
        text: "Accounts",
        to: "/accounts",
        icon: mdiAccount,
      },
      {
        text: "Conversations",
        to: "/conversations",
        icon: mdiMessage,
      },
      {
        text: "Assign VAs",
        to: "/manage-assignment",
        icon: mdiClipboardAccount,
      },
    ];

    function logout() {
      localStorage.setItem("accessToken", null);
      localStorage.setItem("userData", null);
      this.$router.push({ name: "login" });
    }

    return {
      state,
      logout,
      profilePicture,
      navMenuItems,

      userData,

      icons: {
        mdiLogout,
        mdiViewDashboard,
        mdiMessage,
        mdiClipboardAccount,
        mdiAccount,
      },
    };
  },
};
</script>

<style>
.dashboard-wrapper {
  flex-grow: 1;
}

header {
  flex-basis: 0px;
}
</style>
